import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

export interface IResponseError {
    response?: {
        data: {
            messages: {
                content: string,
                field?: string,
            }[]
        }
    }
    request?: any,
}

export interface IError {
    content: string,
    field: string
    type: string,
}

export function getErrorsFromResponse(
    err: IResponseError,
    t:ReturnType<typeof useI18next>['t']
): IError[] {
    let errors: IError[] = [];

    if (err.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        if (err.response.data && err.response.data.messages) {
            err.response.data.messages.forEach((msg) => {
                errors.push({
                    content: msg.content,
                    field: msg.field ? msg.field : 'global',
                    type: 'error',
                });
            });
        }
    } else if (err.request) {
        // The request was made but no response was received
        errors.push({
            content: t('form.error.network'),
            field: 'global',
            type: 'error',
        });
    } else {
        // Something happened in setting up the request and triggered an Error
        errors.push({
            content: t('form.error.global'),
            field: 'global',
            type: 'error',
        });
    }

    return errors;
}

interface IFormErrors {
    [key: string]: string,
}

export function getFormErrors(
    err: IResponseError,
    t:ReturnType<typeof useI18next>['t']
): IFormErrors {
    const errors = getErrorsFromResponse(err, t);
    return errors.reduce((acc: IFormErrors, error) => {
        acc[error.field] = error.content;
        return acc;
    }, {});
}