import axios from "axios";

import { config } from '../config';
import { IConsentFormattedValue } from "../models/consent.model";

export interface INewsletterSubscribeData {
    subscriber: {
        name: string,
        email: string,
    }
    newsletters: number[],
    consents?: IConsentFormattedValue[],
}

export function newsletterSubscribe(data: INewsletterSubscribeData): Promise<any> {
    return axios.post(
        config.endpoints.newsletter.subscribe,
        data,
    );
}

export function newsletterDownload(key: string): Promise<any> {
    return axios.get(config.endpoints.newsletter.download(key), { params: { validate: true }});
}
