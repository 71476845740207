import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    logoRow,
    logo,
    content,
    title,
    subtitle,
    formBox,
    catalog01,
    catalog02,
} from './signup.module.scss';
import { grid } from '../styles/grid.module.scss';
import { ISitePageContext } from '../models/site-page.model';
import { useTranslation } from '../hooks/use-translation';
import { config } from '../config';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import SignupForm from '../components/organisms/signup-form';

const { translationKeys } = config;
const logoSrc = '../assets/images/logo-verona-light-large.png';
const catalog01Src = '../assets/images/catalog-01.png';
const catalog02Src = '../assets/images/catalog-02.png';

interface ISignupProps {
    readonly pageContext: ISitePageContext;
}

const Signup: React.FC<ISignupProps> = ({ pageContext }) => {
    const { t } = useI18next();
    const { label } = useTranslation(pageContext, translationKeys.pageContext);
    return (
        <>
            <SEO title={label} />
            <MainLayout className={layout}>
                <div className={`${logoRow} ${grid}`}>
                    <StaticImage className={logo} src={logoSrc} alt={t('verona.logo.alt')} />
                </div>
                <div className={content}>
                    <h1 className={title}>{t('signup.title')}</h1>
                    <h2 className={subtitle}>{t('signup.subtitle')}</h2>
                    <div className={formBox}>
                        <div className={catalog01}>
                            <StaticImage
                                src={catalog01Src}
                                alt=""
                                quality={75}
                                placeholder="blurred"
                            />
                        </div>
                        <div className={catalog02}>
                            <StaticImage
                                src={catalog02Src}
                                alt=""
                                quality={75}
                                placeholder="blurred"
                            />
                        </div>
                        <SignupForm />
                    </div>
                </div>
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default Signup;
