import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { graphql, useStaticQuery } from 'gatsby';
import * as Yup from 'yup';

import {
    form,
    inputBox,
    input,
    button,
    checkbox,
    checkboxBox,
    successMessage,
} from './signup-form.module.scss';
import { IConsent, IConsentsValuesObject } from '../../models/consent.model';
import { INewsletterSubscribeData, newsletterSubscribe } from '../../api/newsletter';
import { getConsentsFormattedValues } from '../../utils/get-consents-formatted-values';
import { getFormContextId } from '../../utils/get-form-context-id';
import { getConsentsInitialValues } from '../../utils/get-consents-initial-values';
import { getConsentsValidation } from '../../utils/get-consents-validation';
import { getFormErrors, IResponseError } from '../../utils/get-form-errors';
import { config } from '../../config';

import Input from '../atoms/form/input';
import FormikForm from '../hoc/formik-form';
import Button from '../atoms/button';
import ConsentsFields from '../molecules/consents-fields';

const { formContexts, statusMap, newsletters } = config;

interface ISignupFormQuery {
    allConsent: { edges: { node: IConsent }[] };
}

interface ISignupFormProps {
    className?: string;
}

interface ISignupFormValues {
    name: string;
    email: string;
    consents: IConsentsValuesObject;
}

const initialValues: ISignupFormValues = {
    name: '',
    email: '',
    consents: {},
};

const getFieldsValidation = (t: ReturnType<typeof useI18next>['t']) => {
    return {
        name: Yup.string().required(t('form.error.required.field')),
        email: Yup.string().required(t('form.error.required.field')).email(t('form.error.email')),
    };
};

const SignupForm: React.FC<ISignupFormProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const { allConsent }: ISignupFormQuery = useStaticQuery(query);
    const consents = allConsent.edges.map(({ node }) => node);
    const contextId = getFormContextId(consents, formContexts.signup);

    const handleSubmit = (values: ISignupFormValues, helpers: FormikHelpers<ISignupFormValues>) => {
        const { name, email, consents } = values;
        const data: INewsletterSubscribeData = {
            subscriber: {
                name: name,
                email: email,
            },
            newsletters: [newsletters.catalog],
            consents: getConsentsFormattedValues(consents, email, contextId),
        };

        helpers.setStatus(statusMap.loading);

        newsletterSubscribe(data)
            .then((response) => {
                if (response.status === 201) {
                    helpers.setStatus(statusMap.success);
                } else if (response.status === 200) {
                    helpers.setStatus(statusMap.error);
                    helpers.setErrors({
                        email: t('form.error.email.subscribed'),
                    });
                }
            })
            .catch((err: IResponseError) => {
                helpers.setStatus(statusMap.error);
                helpers.setErrors(getFormErrors(err, t));
            });
    };

    return (
        <Formik
            initialValues={{
                ...initialValues,
                consents: getConsentsInitialValues(consents),
            }}
            validationSchema={Yup.object({
                ...getFieldsValidation(t),
                consents: getConsentsValidation(consents, t),
            })}
            onSubmit={handleSubmit}
        >
            {(formik) => {
                return (
                    <FormikForm
                        className={`${form} ${className}`}
                        formik={formik}
                        loaderType="opaque"
                        loaderColor="violet"
                        successMessage={
                            <p className={successMessage}>{t('signup.form.success')}</p>
                        }
                    >
                        <Input
                            isLight={true}
                            className={inputBox}
                            inputClassName={input}
                            name="name"
                            placeholder={t('form.label.name')}
                        />
                        <Input
                            isLight={true}
                            className={inputBox}
                            inputClassName={input}
                            name="email"
                            placeholder={t('form.label.email')}
                        />
                        <ConsentsFields
                            name="consents"
                            consents={consents}
                            inputClassName={checkbox}
                            isLight={true}
                            boxClassName={checkboxBox}
                        />
                        <Button className={button} color="violet">
                            {t('button.send')}
                        </Button>
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

const query = graphql`
    query {
        allConsent(
            filter: { forms: { elemMatch: { systemName: { eq: "form-catalog-signup" } } } }
        ) {
            edges {
                node {
                    ...consentFields
                }
            }
        }
    }
`;

export default SignupForm;
